import styled from 'styled-components';

import Message from '@splunk/react-ui/Message';

import { getConfig } from '@/lib/global';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
`;

const message =
  'Splunkbase will be undergoing a scheduled maintenance. Some functionality may be limited Sep 5, 2024, from 11AM to 1PM CEST';

const NotificationBanner = () => {
  const { NOTIFICATION_BANNER_ENABLED } = getConfig();

  return NOTIFICATION_BANNER_ENABLED ? (
    <StyledContainer>
      <Message appearance="fill" type="warning" role="alert">
        {message}
      </Message>
    </StyledContainer>
  ) : (
    <></>
  );
};

export default NotificationBanner;
