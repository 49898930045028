import SplunkOtelWeb from '@splunk/otel-web';

import packageInfo from './package.json';
import { getConfig, isInBrowser } from './src/lib/global';

const { ENVIRONMENT } = getConfig();

if (isInBrowser() && ENVIRONMENT) {
  SplunkOtelWeb.init({
    realm: 'eu0',
    rumAccessToken: 'tk4RqsWmk8ULM1FvWk37aA',
    applicationName: 'Limelight',
    version: packageInfo.version,
    deploymentEnvironment: ENVIRONMENT,
  });
}
