import { ConvertToUppercaseEnum } from '@/types/genericTypes';
import { ButtonTypeProps, TypographyTypeProps } from '@/types/suiTypes';

export const APPEARANCE: ConvertToUppercaseEnum<ButtonTypeProps['appearance']> = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  FLAT: 'flat',
  DEFAULT: 'default',
  DESTRUCTIVE: 'destructive',
  PILL: 'pill',
  TOGGLE: 'toggle',
};

export const TYPOGRAPHY_WEIGHT: ConvertToUppercaseEnum<TypographyTypeProps['weight']> = {
  LIGHT: 'light',
  NORMAL: 'normal',
  SEMI_BOLD: 'semiBold',
  BOLD: 'bold',
  EXTRA_BOLD: 'extraBold',
  HEAVY: 'heavy',
};

export const TYPOGRAPHY_COLOR: ConvertToUppercaseEnum<TypographyTypeProps['color']> = {
  ACTIVE: 'active',
  DEFAULT: 'default',
  DISABLED: 'disabled',
  INVERTED: 'inverted',
  MUTED: 'muted',
  INHERIT: 'inherit',
};
