import { Feature } from '@/types/splunkbaseTypes';

const featuresForFastInsider = ['limelight'];
const featuresForSlowInsider: string[] = [];

export const hasFastInsiderFeatures = (features: Feature[]) => {
  return features.some((feature) => featuresForFastInsider.includes(feature.name));
};

export const hasSlowInsiderFeatures = (features: Feature[]) => {
  return features.some((feature) => featuresForSlowInsider.includes(feature.name));
};
