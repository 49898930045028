import styled from 'styled-components';

import Dropdown from '@splunk/react-ui/Dropdown';
import Menu from '@splunk/react-ui/Menu';
import Monogram, { getInitials } from '@splunk/react-ui/Monogram';

import Image from 'next/image';

import React, { useContext } from 'react';

import LoginResourcesComponent from '@/components/LoginResources/LoginResources';
import { AuthContext } from '@/lib/appContext';
import { getResourceUrl } from '@/lib/resourceHelper';
import { getLogInUrl, getLogOutUrl } from '@/lib/utils';
import { ActiveButton } from '@/styles/Buttons';
import { APPEARANCE } from '@/text/sui';

export interface LoginComponentProps {
  pathname: string;
}

const StyledImage = styled(Image)`
  padding: 5px !important;
`;
const StyledMonogram = styled.div`
  width: 30px !important;
`;
const StyledTabWithSUIComponent = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;

const LoginComponent: React.FC<LoginComponentProps> = ({ pathname }) => {
  const { userMeta, isLoggedIn } = useContext(AuthContext);
  const userMonogram = (size: number | 'small' | 'medium') => (
    <div>
      <Monogram
        backgroundColor="rgba(255, 255, 255, 0.1)"
        initials={getInitials(userMeta?.display_name || '')}
        size={size}
      />
    </div>
  );

  const toggle = <ActiveButton appearance={APPEARANCE.TOGGLE} label="Log In" isMenu />;

  return (
    <>
      {typeof window !== 'undefined' && isLoggedIn && userMeta && (
        <StyledTabWithSUIComponent>
          <Dropdown toggle={userMonogram('medium')}>
            <Menu style={{ width: 175 }}>
              <Menu.Item icon={<StyledMonogram>{userMonogram('small')}</StyledMonogram>} to="/profile" placeholder="">
                My Profile
              </Menu.Item>
              <Menu.Divider />
              <LoginResourcesComponent />
              <Menu.Divider />
              <Menu.Item
                icon={<StyledImage src={getResourceUrl('/logout.svg')} width={20} height={20} alt="" />}
                to={getLogOutUrl(pathname)}
                placeholder=""
                data-test="log-out"
              >
                Log out
              </Menu.Item>
            </Menu>
          </Dropdown>
        </StyledTabWithSUIComponent>
      )}
      {typeof window !== 'undefined' && !isLoggedIn && (
        <StyledTabWithSUIComponent>
          <Dropdown toggle={toggle}>
            <Menu style={{ width: 175 }}>
              <Menu.Item
                icon={<StyledImage src={getResourceUrl('/icons/login.svg')} width={20} height={20} alt="" />}
                to={getLogInUrl(pathname)}
                placeholder=""
                data-test="log-in"
              >
                Log In
              </Menu.Item>
              <Menu.Divider />
              <LoginResourcesComponent />
            </Menu>
          </Dropdown>
        </StyledTabWithSUIComponent>
      )}
    </>
  );
};

export default LoginComponent;
