import { ImageLoader } from 'next/image';

import { getConfig } from '@/lib/global';

const { IMAGE_URL } = getConfig();

function imageLoaderFactory(url: string): ImageLoader | undefined {
  if (url.endsWith('svg') || !IMAGE_URL) {
    return undefined;
  }

  return ({ src, width, quality = 75 }) => `${IMAGE_URL}/_next/image?url=${src}&w=${width}&q=${quality}`;
}

export default imageLoaderFactory;
