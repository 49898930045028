import { getConfig } from '@/lib/global';

export const getResourceUrl = (url: string) => {
  const { RESOURCE_URL } = getConfig();
  const BASE_URL = RESOURCE_URL ?? '';

  if (url.startsWith('/')) {
    return BASE_URL + '/public/media' + url;
  }

  return url;
};
