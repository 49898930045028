import styled from 'styled-components';

import Button from '@splunk/react-ui/Button';
import variables from '@splunk/themes/variables';

import Link from 'next/link';
import { useRouter } from 'next/router';

import React, { useState } from 'react';

import LoginComponent from '@/components/LoginComponent/LoginComponent';
import NavBarSearch from '@/components/NavBarSearch/NavBarSearch';
import useSubmitNewAppUrl from '@/hooks/useSubmitNewAppUrl';
import { getResourceUrl } from '@/lib/resourceHelper';
import { device } from '@/styles/media_setting';

const StyleNav = styled.div`
  background-color: ${variables.backgroundColorNavigation};
  width: 100%;
`;

const StyleDiv = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  @media ${device.mobileS} {
    width: 100%;
  }

  @media ${device.laptop} {
    width: 1280px;
    margin: 0 auto;
  }
`;

const StyledTab = styled.div`
  cursor: pointer;
  padding: 13px;
`;

const StyledTabWithSUIComponent = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;

const StyledTabWithText = styled.div`
  cursor: pointer;
  padding: 20px;
`;

const StyledLogoLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;

  &[aria-selected='true'] {
    cursor: default;
  }

  &:focus {
    background: url(${getResourceUrl('/logo_colorful.svg')}) no-repeat;
  }
`;

const StyledSvg = styled.div`
  background: url(${getResourceUrl('/logo.svg')}) no-repeat;
  height: 32px;
  width: 152px;
  background-size: contain;
  display: block;

  &[aria-selected='true'] {
    opacity: 1;
  }
  &[aria-selected='false'] {
    opacity: 0.9;
  }

  &:hover,
  &:focus {
    background: url(${getResourceUrl('/logo_colorful.svg')}) no-repeat;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  padding: 20px 0;

  &[aria-selected='true'] {
    cursor: default;
    color: ${variables.contentColorActive};
    font-weight: ${variables.fontWeightBold};
    &:focus {
      filter: drop-shadow(0 0 0.7rem grey);
    }
  }

  &[aria-selected='false'] {
    cursor: pointer;
    color: ${variables.contentColorDefault};
    box-shadow: none;
    font-weight: ${variables.fontWeightSemiBold};
    /* Add an invisible pseudo-element with the same width as the
    title attribute in bold.*/
    &::after {
      display: block;
      content: attr(title);
      font-weight: ${variables.fontWeightBold};
      height: 0;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover {
      font-weight: ${variables.fontWeightBold};
      color: ${variables.contentColorActive};
    }

    &:focus {
      font-weight: ${variables.fontWeightBold};
      color: ${variables.contentColorActive};
    }
  }
`;

const NavBar: React.FC = () => {
  const router = useRouter();
  const { pathname: pathnameString } = router;
  const path = router.asPath;

  const submitNewAppUrl = useSubmitNewAppUrl('/profile');

  const [activeTabId, setActiveTabId] = useState<string | undefined>(pathnameString.replace(/\//g, ''));

  const handleChange = (e: React.MouseEvent<HTMLDivElement>, selectedTab: string) => {
    e.preventDefault();
    setActiveTabId(selectedTab);
  };
  return (
    <StyleNav>
      <StyleDiv>
        <StyledTab onClick={(e) => handleChange(e, 'home')}>
          <StyledLogoLink passHref href="/">
            <StyledSvg />
          </StyledLogoLink>
        </StyledTab>
        <StyledTabWithText onClick={(e) => handleChange(e, 'collections')}>
          <StyledLink
            passHref
            href="/collections"
            data-test="collections"
            title="Collections"
            aria-selected={'collections' === activeTabId}
          >
            Collections
          </StyledLink>
        </StyledTabWithText>
        <StyledTabWithText onClick={(e) => handleChange(e, 'apps')}>
          <StyledLink passHref href="/apps" data-test="apps" title="Apps" aria-selected={'apps' === activeTabId}>
            Apps
          </StyledLink>
        </StyledTabWithText>
        {pathnameString !== '/' && (
          <StyledTabWithSUIComponent style={{ marginLeft: 'auto' }}>
            <NavBarSearch />
          </StyledTabWithSUIComponent>
        )}
        <StyledTabWithSUIComponent style={{ marginLeft: 'auto' }}>
          <Button label="Submit an App" to={submitNewAppUrl} />
        </StyledTabWithSUIComponent>
        <LoginComponent pathname={path} />
      </StyleDiv>
    </StyleNav>
  );
};

export default NavBar;
