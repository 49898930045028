import { useRouter } from 'next/router';

import { useContext } from 'react';

import { AuthContext } from '@/lib/appContext';
import { classicHost } from '@/lib/global';
import { getLogInUrl } from '@/lib/utils';

const useSubmitNewAppUrl = (exposedUrl: '/profile' | '/new-app' | '/agreement') => {
  const router = useRouter();
  const { isLoggedIn, isFastInsiderUser } = useContext(AuthContext);
  if (isFastInsiderUser) {
    return isLoggedIn ? exposedUrl : getLogInUrl(router.asPath);
  } else {
    return `https://${classicHost}/new/`;
  }
};

export default useSubmitNewAppUrl;
