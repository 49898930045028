import { API_V1_SUFFIX } from '@/lib/services/api_v1/constants';
import fetcher from '@/lib/services/fetcher';
import { User } from '@/types/splunkbaseTypes';

const userFields =
  'id,username,apps,apps_editable,apps_subscribed,apps_entitlements,api_tos_accepted,splunk_employee,is_admin,features';

export const fetchUser = async (userName: string): Promise<User> => {
  return await fetcher<User>(`${API_V1_SUFFIX}/user/${userName}/?fields=${userFields}`);
};
