import styled from 'styled-components';

import Menu from '@splunk/react-ui/Menu';

import Image from 'next/image';

import React from 'react';

import { getResourceUrl } from '@/lib/resourceHelper';

const StyledTabWithSUIComponent = styled.div`
  cursor: pointer;
  margin-left: 0px;
`;

const StyledHeading = styled.p`
  margin: 10px;
  margin-top: 0px;
  font-size: 10px;
`;

const StyledImage = styled(Image)`
  padding: 5px !important;
`;

const LoginResourcesComponent = () => {
  return (
    <StyledTabWithSUIComponent>
      <Menu style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
        <StyledHeading>RESOURCES</StyledHeading>
        <Menu.Item
          placeholder=""
          icon={<StyledImage src={getResourceUrl('/icons/documentation.svg')} width={20} height={20} alt="" />}
          to={'/docs'}
        >
          Documentation
        </Menu.Item>
        <Menu.Item
          placeholder=""
          icon={<StyledImage src={getResourceUrl('/icons/answers.svg')} width={20} height={20} alt="" />}
          to={'https://community.splunk.com/t5/Splunk-Answers/ct-p/en-us-splunk-answers'}
        >
          Splunk Answers
        </Menu.Item>
        <Menu.Item
          placeholder=""
          icon={<StyledImage src={getResourceUrl('/icons/groups.svg')} width={20} height={20} alt="" />}
          to={'https://usergroups.splunk.com/'}
        >
          User Groups
        </Menu.Item>
        <Menu.Item
          placeholder=""
          icon={<StyledImage src={getResourceUrl('/icons/dev.svg')} width={20} height={20} alt="" />}
          to={'https://dev.splunk.com'}
        >
          dev.splunk.com
        </Menu.Item>
      </Menu>
    </StyledTabWithSUIComponent>
  );
};

export default LoginResourcesComponent;
