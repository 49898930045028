import Menu from '@splunk/react-ui/Menu';
import variables from '@splunk/themes/variables';

import Image from 'next/image';
import { useRouter } from 'next/router';

import imageLoaderFactory from '@/lib/imageLoaderFactory';
import { getResourceUrl } from '@/lib/resourceHelper';
import { sendGTMClickSearchResult, sendGTMViewSearchResult } from '@/lib/sharedFunctions';
import { Suggestion } from '@/types/splunkbaseTypes';

export interface AutocompleteProps {
  searchQuery: string;
  results: Suggestion[];
  onContentClick: () => void;
  width: number;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ searchQuery, results, onContentClick, width }) => {
  const router = useRouter();
  const {
    query: { filters },
  } = router;

  const contentClick = (id: string) => {
    sendGTMViewSearchResult(searchQuery, 'Dropdown');
    sendGTMClickSearchResult(searchQuery, 'Dropdown', `${window.location.host}/app/${id}`);
    onContentClick();
  };
  const handleArchivedClick = () => {
    const archivedQuery = `archive:true`;
    let newFilters;

    if (!filters) {
      newFilters = archivedQuery;
    } else if (filters.length > 0 && filters.indexOf('archive') < 0) {
      // only add archivedQuery when archived apps is not already selected
      newFilters = filters.concat('/' + archivedQuery);
    } else {
      newFilters = filters;
    }

    contentClick('');

    router.push(
      {
        pathname: '/apps',
        query: {
          keyword: searchQuery,
          filters: newFilters,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  const backgroundColor = `${variables.backgroundColorNavigation}`;

  const renderMenu = results.map((result, idx) => {
    return (
      <Menu.Item
        key={idx}
        icon={
          result.icon_url && (
            <Image
              alt="suggestion icon"
              src={getResourceUrl(result.icon_url)}
              width={30}
              height={30}
              loader={imageLoaderFactory(result.icon_url)}
            />
          )
        }
        placeholder=""
        to={`/app/${result.id}`}
        onClick={() => contentClick(result.id.toString())}
      >
        {result.text}
      </Menu.Item>
    );
  });

  const Footer = (
    <>
      <Menu.Divider />
      <Menu.Item
        key="ach231"
        description="Not seeing what you're looking for? The app may be archived."
        onClick={handleArchivedClick}
        placeholder=""
      >
        View Archived Solutions
      </Menu.Item>
    </>
  );

  return (
    <Menu style={{ width, backgroundColor }}>
      {results && results.length ? (
        renderMenu
      ) : (
        <Menu.Item disabled key="noResults" placeholder="">
          No Results Found
        </Menu.Item>
      )}
      {Footer}
    </Menu>
  );
};

export default Autocomplete;
