import SplunkThemeProvider from '@splunk/themes/SplunkThemeProvider';

import type { AppProps } from 'next/app';

import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import Layout from '@/components/Layout/Layout';
import { getFilters } from '@/lib/api/api_v2/filters';
import { AuthContext, ProductContext } from '@/lib/appContext';
import { hasFastInsiderFeatures, hasSlowInsiderFeatures } from '@/lib/featureFlags';
import { cookieMap } from '@/lib/global';
import { fetchUser } from '@/lib/services/api_v1/user';
import SWRConfigProvider from '@/providers/swrProvider';
import '@/styles/fonts.css';
import '@/styles/globals.css';
import { Filter, UserAuthData, UserMeta } from '@/types/splunkbaseTypes';

import packageInfo from '../../package.json';
import '../../splunk-instrumentation';

declare global {
  interface Window {
    limelightVersion?: string;
  }
}

function App({ Component, pageProps }: AppProps) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userAuthData, setUserAuthData] = useState<UserAuthData>({} as UserAuthData);
  const [isFastInsiderUser, setIsFastInsiderUser] = useState<boolean>(false);
  const [isSlowInsiderUser, setIsSlowInsiderUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userMeta, setUserMeta] = useState<UserMeta>({} as UserMeta);
  const [availableProducts, setAvailableProducts] = useState<Filter[]>([]);
  const [availableCategories, setAvailableCategories] = useState<Filter[]>([]);

  // parse cookies for auth cookie
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  const allCookies = typeof window !== 'undefined' ? window.document.cookie : '';
  const cookiesArray: string[] = allCookies ? allCookies.split('; ') : [];

  // If login is not working - verify that the correct cookie is being looked at for the environment
  const cookieLookup = cookieMap[hostname];

  const authCookie: string | null =
    (cookiesArray && cookieLookup && cookiesArray?.find((cookie) => cookie.startsWith(cookieLookup))) || null;

  const authCookieValue = authCookie?.split('=').at(1)?.split('.').at(1);

  const decodedUserInfo = (authCookieValue && JSON.parse(atob(authCookieValue))) || null;
  const [hasMounted, setHasMounted] = useState(false);

  const tagManagerArgs = {
    gtmId: 'GTM-TPV7TP',
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await fetchUser(userAuthData.user);
        setUserMeta({ ...user, ...userAuthData });
        setIsFastInsiderUser(hasFastInsiderFeatures(user.features));
        setIsSlowInsiderUser(hasSlowInsiderFeatures(user.features));
      } catch {
        throw Error;
      }
    };

    if (userAuthData && userAuthData.user) {
      fetchUserData();
    }
    setIsLoading(false);
  }, [isLoggedIn]);

  useEffect(() => {
    if (decodedUserInfo) {
      setIsLoggedIn(true);
      setUserAuthData(decodedUserInfo);
    } else {
      setIsLoggedIn(false);
      setUserAuthData({} as UserAuthData);
    }
  }, []);

  useEffect(() => {
    // fetch product types and setAvailableProducts for global context
    const fetchFilters = async () => {
      try {
        const availableProducts: Filter[] = [];
        const filters = await getFilters();
        filters?.forEach((filter) => {
          if (filter.key === 'categories') {
            setAvailableCategories(filter.options || []);
          }
          filter.options?.forEach((filterOpt) => {
            filterOpt.options?.forEach((nestedOption) => {
              if (nestedOption.key === 'product_types') {
                nestedOption.options?.forEach((opt) => {
                  availableProducts.push({ key: opt.key, label: opt.label });
                });
              }
            });
          });
        });
        if (availableProducts.length > 0) {
          setAvailableProducts([...availableProducts]);
        }
      } catch {
        throw Error;
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    window.limelightVersion = packageInfo.version;
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <SWRConfigProvider>
      <SplunkThemeProvider family="prisma" density="comfortable" colorScheme="dark">
        <ProductContext.Provider value={{ availableProducts, availableCategories }}>
          <AuthContext.Provider
            value={{
              userAuthData,
              userMeta,
              isFastInsiderUser,
              isSlowInsiderUser,
              isLoggedIn,
              isLoading,
            }}
          >
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </AuthContext.Provider>
        </ProductContext.Provider>
      </SplunkThemeProvider>
    </SWRConfigProvider>
  );
}

export default App;
