import styled from 'styled-components';

import Link from '@splunk/react-ui/Link';
import variables from '@splunk/themes/variables';

import { classicHost } from '@/lib/global';
import { contentGradient } from '@/styles/layout';
import { AppCardText } from '@/styles/typography';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${variables.backgroundColorNavigation};
  width: 100%;
  height: 30px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid;
  border-image: ${contentGradient.dividerGradient};
`;

const StyledText = styled(AppCardText)`
  color: ${variables.contentColorActive};
`;

const bannerText = 'Welcome to the new Splunkbase! To return to the old Splunkbase, ';

const PreviewBanner = () => (
  <StyledContainer>
    <StyledText>
      {bannerText}
      <Link to={`https://${classicHost}/`} style={{ fontWeight: 700 }}>
        click here.
      </Link>
    </StyledText>
  </StyledContainer>
);

export default PreviewBanner;
