import styled from 'styled-components';

import { PropsWithChildren } from 'react';

import Footer from '@/components/Footer/Footer';
import NavBar from '@/components/NavBar/NavBar';
import NotificationBanner from '@/components/NotificationBanner/NotificationBanner';
import PreviewBanner from '@/components/PreviewBanner/PreviewBanner';
import { contentColors } from '@/styles/layout';
import { device } from '@/styles/media_setting';

const StylePage = styled.div`
  background-color: ${contentColors.backgroundColorPage};
  @media ${device.mobileS} {
    width: 1440px;
    margin: 0 auto;
  }
  @media ${device.laptopL} {
    width: 100%;
  }
`;

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StylePage>
      <NotificationBanner />
      <PreviewBanner />
      <NavBar />
      {children}
      <Footer />
    </StylePage>
  );
};

export default Layout;
